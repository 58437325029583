<template>
    <div class="offer-wrapper" @click="showBuyPopupAction">
        <div class="lot-image-wrapper">
            <img class="lot-image" :src="`/img/lots/lot-${reward}.png`" alt="pearls" />
        </div>
        <h5>{{ name }}</h5>
        <p class="offer-reward">{{ formatNumber(reward, ",") }} PEARL</p>
        <div class="busd-wrapper">
            <img src="/img/icons/busd.svg" loading="lazy" alt="" width="24">
            <p class="price">{{ formatNumber(price, ",") }} BUSD</p>
        </div>

        <AppWalletUnlock v-if="showPopup" @success="onSuccess" @close="closeWalletSelect" />

        <el-dialog
            v-if="showBuyPopup"
            v-model="showBuyPopup"
            title="Purchase Confirmation"
            :append-to-body="true"
            :fullscreen="isMobile"
            class="buy-popup"
        >

            <div class="placeholder">
                <img class="lot-image-popup" :src="`/img/lots/lot-${reward}.png`" alt="pearls" />
            </div>
            <h5>{{ name }}</h5>
            <div class="price-row">
                <p class="mg-bottom-0">Price</p>
                <div class="busd-wrapper">
                    <img src="/img/icons/busd.svg" loading="lazy" alt="" width="24">
                    <p class="price">{{ formatNumber(price, ",") }} BUSD</p>
                </div>
            </div>
            <div class="price-row">
                <p class="mg-bottom-0">You get</p>
                <p class="mg-bottom-0">{{ formatNumber(reward, ",") }} PEARL</p>
            </div>
            <div class="price-row balance" v-if="mainAccount">
                <p class="mg-bottom-0">Your Balance</p>
                <div class="busd-wrapper" >
                    <img src="/img/icons/busd.svg" loading="lazy" alt="" width="24">
                    <span v-if="balance !== ''" class="price small" :class="[{'text-green': isEnoughBalance}, {'text-red': !isEnoughBalance}]">
                        {{ formatNumber(balance, ",") }} BUSD
                    </span>
                </div>
            </div>

            <div class="notice">
                <el-checkbox id="agree-cb" type="checkbox" v-model="agreeToRules" class="checkbox" size="large">
                    I acknowledge that upon transaction completion, my Pearl tokens are committed to a 1-year lock on the game's smart contract. Subsequently, a gradual 5% monthly token unlock will initiate. Manual unstaking, not automatic, will be possible upon request.
                </el-checkbox>
            </div>


            <div class="btns-wrapper">
                <button
                    @click="checkAndBuyPearlPack(price)"
                    :loading="loadingBuyBtn || loadingAllowBtn"
                    class="btn-primary"
                    size="large"
                    :disabled="!agreeToRules"
                >
                    <span>
                        Buy and stake
                        <span class="line-rounded-icon link-icon-right" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg); transform-style: preserve-3d;"></span>
                    </span>
                    <span v-if="allowConfirmCount && loadingAllowBtn">
                        &nbsp;{{ allowConfirmCount }} / {{ minConfirmationsCount }}
                    </span>

                    <span v-if="buyConfNumber && loadingBuyBtn">
                        &nbsp;{{ buyConfNumber }} / {{ minConfirmationsCount }}
                    </span>
                </button>
            </div>
        </el-dialog>

        <el-dialog
            v-if="showSuccessPopup"
            v-model="showSuccessPopup"
            :fullscreen="isMobile"
            title="Confirmed"
            :append-to-body="true"
            class="popup-confirm buy-popup"
        >
            <div class="placeholder">
                <img class="lot-image-popup" :src="`/img/lots/lot-${reward}.png`" alt="pearls" />
            </div>

            <div class="price-wrap">
                <div>Bought Pearls</div>
                <div class="price-wrap-right">{{ formatNumber(reward, ",") }} PEARL</div>
            </div>

            <div class="price-wrap">
                <div>Spent</div>
                <div class="price-wrap-right">
                    <img src="/img/icons/busd.svg" loading="lazy" alt="" width="24">
                    <span class="balance-spec">{{ formatNumber(price, ",") }} BUSD</span>
                </div>
            </div>

            <div class="price-wrap medium" v-if="mainAccount">
                <div>Transaction</div>
                <div class="price-wrap-right">
                    <a :href="linkToTransaction" target="_blank">Explorer</a>
                </div>
            </div>

            <div class="btns-wrapper">

                <button @click="showSuccessPopup = false" class="btn-primary" size="large">
                    OK
                </button>
            </div>
        </el-dialog>

        <el-dialog class="popup-canceled buy-popup" v-if="showCancelPopup" v-model="showCancelPopup" :fullscreen="isMobile" title="Canceled" :append-to-body="true">
            <div class="placeholder">
                <img class="lot-image-popup" :src="`/img/lots/lot-${reward}.png`" alt="pearls" />
            </div>

            <p>{{ cancelError }}</p>
            <p><b class="flex-icon">Spent:&nbsp;&nbsp;<img class="small-icon" src="/img/icons/busd.svg" loading="lazy" alt="" width="16">0 BUSD</b></p>

            <div class="btns-wrapper">

                <button @click="showCancelPopup = false" class="btn-primary" size="large">
                    OK
                </button>
            </div>
        </el-dialog>

        <el-dialog  title="Insufficient Funds" v-if="showLowBalance" v-model="showLowBalance" :fullscreen="isMobile" class="buy-popup" :append-to-body="true">

            <div>
                <p>The <b>Pearl package</b> you’re about to purchase costs <b class="flex-icon"><img class="small-icon" src="/img/icons/busd.svg" loading="lazy" alt="" width="16">{{ formatNumber(price, ",") }} BUSD</b>.</p>
                <p>
                    Unfortunately you don’t have enough funds to complete the transaction.
                </p>
                <p>
                    <b>Please top up your balance on PancakeSwap</b> or transfer the needed amount of BUSD to your wallet manually.
                </p>
            </div>
            <div class="btns-wrapper">
                <a href="https://pancakeswap.finance/?chain=bscTestnet" target="_blank">
                    <button @click="showCancelPopup = false" class="btn-primary" size="large">
                        <span>Top Up</span>
                    </button>
                </a>
            </div>
        </el-dialog>
    </div>
</template>


<script lang="ts">
    import { defineComponent } from "vue";
    import { BigNumber } from "bignumber.js";
    import { ConfigInstance } from "~/composables/Config";
    import type { ProviderAccount } from "~/stores/mainStore";
    import { mapState, mapActions } from "pinia";
    import { useMainStore } from "~/stores/mainStore";
    import { Web3StakingService } from "~/composables/Web3StakingService";
    import AppWalletUnlock from "~/components/AppWalletUnlock.vue";
    import { ElDialog, ElCheckbox } from "element-plus";
    import { formatNumber, modifyHistoryHashStatus, saveHashToLocalStorage, stringifyError } from "~/composables/utils/utils";
    import { PearlReader } from "~/composables/PearlReader";
    import PearlApiService from "~/composables/PearlApiService";


    export default defineComponent({
        name: "BuyPackButton",
        emits: ["success"],
        components: {
            AppWalletUnlock,
            "el-dialog": ElDialog,
            "el-checkbox": ElCheckbox,
        },
        props: {
            price: {
                required: true,
                type: Number,
            },
            reward: {
                required: true,
                type: Number,
            },
            name: {
                required: false,
                type: String,
            }
        },
        data(): {
            allowConfirmCount: number;
            allowedCount: number;
            loadingAllowed: boolean;
            loadingAllowBtn: boolean;
            loadingBuyBtn: boolean;
            allowed: boolean;
            minConfirmationsCount: number;
            showPopup: boolean;
            storedAction: Function | null;
            btnLoading: boolean;
            buyConfNumber: number;
            showBuyPopup: boolean;
            balance: string;
            loadingBalance: boolean;
            showLowBalance: boolean;
            showCancelPopup: boolean;
            showSuccessPopup: boolean;
            lastHash: string;
            cancelError: string;
            agreeToRules: boolean;
        } {
            return {
                allowConfirmCount: 0,
                allowedCount: 0,
                loadingAllowed: false,
                loadingAllowBtn: false,
                loadingBuyBtn: false,
                allowed: false,
                minConfirmationsCount: ConfigInstance.blockchain.minConfirmationsCount,
                showPopup: false,
                storedAction: null,
                btnLoading: false,
                buyConfNumber: 0,
                showBuyPopup: false,
                balance: "",
                loadingBalance: false,
                showLowBalance: false,
                showCancelPopup: false,
                showSuccessPopup: false,
                lastHash: "",
                cancelError: "",
                agreeToRules: false,
            };
        },
        computed: {
            ...mapState(useMainStore, ["providerAccounts"]),
            mainAccount(): ProviderAccount | null {
                return this.providerAccounts[0] ?? null;
            },
            isEnoughBalance(): boolean {
                return new BigNumber(this.balance).gte(this.price);
            },
            linkToTransaction(): string {
                return ConfigInstance.blockchain.blockScanUrl + "/tx/" + this.lastHash;
            },
            isMobile(): boolean {
                if (window && window.innerWidth) {
                    return window.innerWidth < 720;
                } else {
                    return false;
                }
            }
        },
        methods: {
            ...mapActions(useMainStore, ["updateVersion"]),
            async checkAndBuyPearlPack(quantity: number) {
                this.cancelError = "";
                if (!this.mainAccount) {
                    this.showPopup = true;
                    return;
                }
                const service = new Web3StakingService(
                    this.mainAccount, ConfigInstance.blockchain
                );
                const reader = new PearlReader(ConfigInstance.blockchain);
                this.allowConfirmCount = 0;
                this.loadingBuyBtn = true;
                try {
                    const allowedCount = await reader.allowance(
                        this.mainAccount.address,
                        ConfigInstance.blockchain.contracts.USDT
                    );

                    this.loadingBuyBtn = false;

                    await this.updateBalance();
                    if (!this.isEnoughBalance) {
                        this.showLowBalance = true;
                        return;
                    }

                    // get allowance equal to quantity
                    if (new BigNumber(allowedCount).isLessThan(quantity)) {
                        this.loadingAllowBtn = true;
                        const hash = await service.approve(ConfigInstance.blockchain.contracts.USDT, quantity, (allowConfNumber: number) => {
                            this.allowConfirmCount = allowConfNumber;
                            if (allowConfNumber == this.minConfirmationsCount) {
                                this.loadingAllowBtn = false;
                                this.buyPearlPack(quantity, service);
                                modifyHistoryHashStatus(hash, "Done");
                                this.updateVersion();
                            }
                        });

                        saveHashToLocalStorage("Approve", hash);
                        this.updateVersion();
                    } else {
                        this.loadingBuyBtn = true;
                        await this.buyPearlPack(quantity, service);
                    }
                } catch (e: unknown) {
                    this.loadingAllowBtn = false;
                    this.loadingBuyBtn = false;
                    this.showBuyPopup = false;
                    this.cancelError = stringifyError(e);
                    this.showCancelPopup = true;
                    console.error(e);
                }
            },
            async buyPearlPack(quantity: number, service: Web3StakingService) {
                this.lastHash = "";
                this.buyConfNumber = 0;
                this.loadingBuyBtn = true;
                this.cancelError = "";
                try {
                    const hash = await service.buyPearlPack(quantity.toString(), (confNumber: number) => {
                        this.buyConfNumber = confNumber;
                        if (confNumber == this.minConfirmationsCount) {
                            modifyHistoryHashStatus(hash, "Done");
                            this.updateVersion();
                        }
                    });
                    saveHashToLocalStorage("Buy pearl pack " + this.price, hash);
                    await PearlApiService.pushActivity({
                        address: this.mainAccount?.address ?? "",
                        transactionHash: hash,
                        value: this.reward,
                        type: "PURCHASE"
                    });
                    this.updateVersion();
                    this.loadingBuyBtn = false;
                    this.$emit("success");
                    this.showBuyPopup = false;
                    this.showSuccessPopup = true;
                    this.lastHash = hash;
                } catch (e: unknown) {
                    this.showBuyPopup = false;
                    this.loadingBuyBtn = false;
                    this.cancelError = stringifyError(e);
                    this.showCancelPopup = true;
                }
            },
            async updateBalance(): Promise<void> {
                this.balance = "";
                if (!this.mainAccount) {
                    return;
                }
                this.loadingBalance = true;

                try {
                    const balance = await new PearlReader(ConfigInstance.blockchain).availableTokens(
                        this.mainAccount.address, ConfigInstance.blockchain.contracts.USDT
                    );
                    this.balance = new BigNumber(balance).toFixed(2);
                } finally {
                    this.loadingBalance = false;
                }
            },
            closeWalletSelect() {
                this.storedAction = null;
                this.showPopup = false;
            },
            async showBuyPopupAction() {
                this.showBuyPopup = true;
                await this.updateBalance();
            },
            async onSuccess() {
                this.showPopup = false;
                await this.checkAndBuyPearlPack(this.price);
            },
            formatNumber(num: number | string, delim: string) {
                return formatNumber(num, delim);
            }
        },
    });
</script>

<style lang="scss">

    h5 {
        color: #fff;
        margin-top: 0;
        margin-bottom: 10px;
        font-family: Grenze, sans-serif;
        font-size: 20px;
        font-weight: 800;
        line-height: 1.333em;
        text-align: center;
    }

    .lot-image {
        max-width: 90%;
        display: inline-block;
    }

    .lot-image-popup {
        max-width: 100%;
        max-height: 200px;
        margin: 10px 0;
    }

    .price-wrap {
        display: flex;
        color: var(--second-text-color);
        font-size: 18px;
        font-weight: 400;
        margin-top: 16px;
        font-family: Eczar, sans-serif;

        @media screen and (max-width: 900px) {
            font-size: 18px;
        }
    }

    .price-wrap-right {
        margin-left: auto;
        color: var(--main-text-color);
        display: flex;
    }

    .medium {
        text-transform: capitalize;
        // font-size: 20px;
        font-weight: normal;
        border-top: 1px solid rgba(255, 255, 255, .1);
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .placeholder {
        border-radius: 12px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .lot-image-wrapper {
        width: 100%;
        height: 125px;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: visible;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
        .lot-image-wrapper {
            height: 100px;
        }
    }


    .offer-wrapper {
        align-items: center;
        text-align: center;
        flex-direction: column;
        display: flex;
    }

    .busd-wrapper {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .offer-reward {
        margin-bottom: 0;
    }

    h5 {
        color: var(--main-text-color);
        margin-top: 0;
        margin-bottom: 10px;
        font-family: Grenze, sans-serif;
        font-size: 20px;
        font-weight: 800;
        line-height: 1.333em;
    }

    @media screen and (max-width: 767px) {
        h5 {
            font-size: 16px;
        }
    }


    .price {
        color: var(--main-text-color);
        margin-bottom: 0;
        padding-left: 8px;
        font-size: 24px;
        font-weight: 500;
    }

    @media screen and (max-width: 991px) {
        .price {
            font-size: 16px;
        }
    }

    @media screen and (max-width: 479px) {
        .price {
            padding-left: 8px;
            font-size: 13px;
            line-height: 144%;
        }
    }

    .text-green {
        color: var(--confirm-color);
    }

    .text-red {
        color: var(--canceled-color);
    }

    .el-button--primary {
        margin-top: 6px;
    }

    .btns-wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: center;
    }

    .buy-btn {
        display: flex;
        padding: 12px 38px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 86px;
        background: var(--main-blue);
        text-align: center;
        font-size: 24px;
        color: var(--main-text-color);
        border: 1px solid transparent;

        &:active,
        &:focus,
        &:hover {
            background: transparent;
            border: 1px solid #939393;
        }
    }

    .subtitle {
        text-align: center;
        font-weight: 700;
        color: var(--main-text-color);
        font-size: 24px;
    }

    .popup-text {
        color: var(--main-text-color);
        text-align: center;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .checkbox {
        margin-right: 6px;
    }

    .notice {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .balance-spec {
        padding-left: 8px;
    }

    .price-row {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        display: flex;
        font-size: 18px;
        color: var(--second-text-color);
        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    .price-row.balance {
        border-top: 1px solid rgba(255, 255, 255, .1);
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .mg-bottom-0 {
        margin-bottom: 0;
    }

    .price.small {
        font-size: 18px;
    }

    .flex-icon {
        display: inline-flex;
        align-items: center;
    }

    .small-icon {
        margin-right: 4px;
    }
</style>

<style>
    .el-checkbox {
        white-space: normal;
    }
</style>
