<template>
    <div class="purchase-body">
        <div class="scene-wrapper">
            <div class="scene">
                <TiledScene v-for="(tilemap, i) in tilemaps" :tilemap="tilemap" :key="i" />

            </div>
            <MapScrollIndicator />
        </div>

        <div class="grid-wrapper">
            <div class="sale-wrapper">
                <SaleStatus :key="version" />
            </div>

            <div class="shop-wrapper">

                <div class="offers-wrapper card main-cards">
                    <h4 class="offers-title">
                        Tortuga Marketplace
                    </h4>
                    <div class="w-layout-grid offers-list">
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="100" :reward="10000" @success="updateData" name="Handful of Pearls" />
                        </div>
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="500" :reward="50000" @success="updateData" name="Shell Full of Pearls" />
                        </div>
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="1000" :reward="100000" @success="updateData" name="Pouch Full of Pearls" />
                        </div>
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="2500" :reward="250000" @success="updateData" name="Bag Full of Pearls" />
                        </div>
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="10000" :reward="1000000" @success="updateData" name="Plate Full of Pearls" />
                        </div>
                        <div class="offer card pd-48px---32px shop-item w-inline-block">
                            <BuyPackButton :price="25000" :reward="25000000" @success="updateData" name="Tank Full of Pearls" />
                        </div>
                    </div>
                    <p class="text-block">
                        When purchasing Pearl tokens from Tortuga Marketplace they are automatically staked and locked on the game smart-contract for a period of 1 year, followed by a 5% monthly linear unlock schedule. Pearl purchase from the Marketplace provides players with an automatic opportunity to participate in daily seabed-retrieved Pearl distributions (for up to 60% of the purchased token amount per year). Once unstaked and claimed, purchased Pearl tokens will not be eligible for any new in-game staking procedures.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import BuyPackButton from "~/components/BuyPackButton.vue";
    import SaleStatus from "~/components/SaleStatus.vue";
    import type TiledMap from "tiled-tmj-typedefs/types/TiledMap";
    import TiledScene from "~/components/TiledScene.vue";
    import MapScrollIndicator from "~/components/MapScrollIndicator.vue";
    import { useHeadSafe } from "@unhead/vue";
    import { ref, onMounted } from "vue";

    useHeadSafe({
        title: "Pearl Diver Island - Marketplace",
    });

    const version = ref(0);
    const loading = ref(false);
    const tilemaps = ref<readonly TiledMap[]>([]);

    onMounted(async () => {
        loading.value = true;
        tilemaps.value  = await Promise.all([
            fetch("/assets/new_scenes/Scene_2.tmj").then(rs => rs.json()),
        ]);
        loading.value = false;
    });

    function updateData() {
        version.value = version.value++;
    }
</script>

<style scoped lang="scss">
    .purchase-body {
        display: flex;
        justify-content: center;
        gap: 37px;
        flex-wrap: wrap;
        align-items: flex-start;


        @media screen and (max-width: 900px) {
            gap: 0;
        }

        @media screen and (max-width: 767px) {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .offers-list {
        margin-bottom: 48px;
    }

    .card {
        overflow: hidden;
    }
    .w-layout-grid {
        grid-row-gap: 16px;
        grid-column-gap: 16px;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;
    }

    .offers-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 20px;
        color: var(--main-text-color);
        font-weight: 700;
        font-family: Grenze, sans-serif;
    }

    .offers-wrapper {
        border-radius: 12px;
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid var(--blue-2);
        background: var(--blue-3);
        margin-bottom: 20px;
    }

    .sale-wrapper,
    .shop-wrapper {
        margin-top: 10px;
        grid-area: span 1 / span 1 / span 1 / span 1;
    }

    .offer {
        display: flex;
        align-items: baseline;
        transition: ease all 200ms;
        cursor: pointer;
    }

    .card.pd-48px---32px {
        padding: 53px 33px;
        padding-top: 53px;
        padding-bottom: 53px;
    }

    .card.pd-48px---32px.shop-item {
        color: rgba(255, 255, 255, .5);
        background-color: #021b26;
        background-image: radial-gradient(circle closest-corner at 50% 0, #3b7285, rgba(0, 0, 0, 0));
        padding-top: 20px;
        padding-bottom: 20px;
        text-decoration: none;
        transition: all .3s, color .3s;

        &:hover {
            background-image: radial-gradient(circle closest-corner at 50% 0, #3faa58, rgba(0, 0, 0, 0));
        }
    }

    @media screen and (max-width: 991px) {
        .card.pd-48px---32px.shop-item {
            background-image: radial-gradient(circle farthest-side at 50% 0, #3b7285, rgba(0, 0, 0, 0) 50%);
            flex-direction: column;
            justify-content: flex-end;
            align-items: stretch;
            padding: 16px 20px;
            display: flex;
        }
    }


    @media screen and (max-width: 767px) {
        .card.pd-48px---32px.shop-item {
            padding-left: 16px;
            padding-right: 16px;
        }
    }


    @media screen and (max-width: 479px) {
        .card.pd-48px---32px.shop-item {
            padding: 12px 8px;
            font-size: 12px;
        }
    }

    .scene-wrapper {
        width: 100%;
    }

    .grid-wrapper {
        grid-column-gap: 28px;
        grid-row-gap: 28px;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        align-items: center;
        display: grid;
        align-items: flex-start;
    }

    @media screen and (max-width: 991px) {
        .grid-wrapper {
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            grid-template-columns: .75fr 1fr;
        }
    }

    @media screen and (max-width: 767px) {
        .grid-wrapper {
            flex-direction: column;
            display: flex;
        }
    }
</style>