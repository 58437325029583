<template>
    <div>
        <div class="card main-cards">
            <h4 class="sale-title">Total Token Availability</h4>

            <div class="token-supply-wrapper">
                <div class="token-info-wrapper">
                    <div class="text-white">Acquired</div>
                    <div class="text-numbers">{{ format(saleAmount) }}</div>
                </div>
                <div class="token-progress-wrapper">
                    <div class="progress-bar-bg">
                        <div class="progress-bar-full" :style="`width: ${percent}%`"></div>
                        <div class="progress-bar-text-wrapper">
                            <div>10%</div>
                        </div>
                    </div>
                </div>
                <div class="token-info-wrapper last">
                    <div class="text-white">For Sale</div>
                    <div class="text-numbers">{{ format(sold) }}</div>
                </div>
            </div>

            <p class="text-block">
                This progress bar displays the Pearl token availability for purchase on Tortuga Marketplace. As this sale sponsors the hiring of professional pearl divers to retrieve the remaining scattered Pearl tokens from the seabed – <b>only Pearls obtained from this Marketplace provide players with the rights to automatically participate in daily reward distributions of Pearls retrieved from the seabed</b>, proportionally to the current held Pearl market share of each player. All purchased Pearls are automatically staked and locked on game smart-contract for 1 year, followed by a 5% linear monthly unlock schedule afterwards.
            </p>
            <p>
                Captain Peg-Leg Pete, who is organizing this pearl diving operation is willing to share up to 60% of the seabed-retrieved Pearls with initial buyers. It looks like those players who are smart and brave enough to back his endeavor quickly might benefit from it big time.
            </p>
        </div>


        <div class="card main-cards">
            <div class="max-token-supply-wrapper">
                <h4 class="sale-title">Current Max Pearl Supply</h4>
                <div class="max-total-supply pearl-wrapper">
                    <img src="/img/pearltoken.svg" loading="lazy" width="48">
                    <p class="total-supply">{{ format(maxTotalSupply) }}</p>
                </div>
                <div class="btns-wrapper"><a class="btn-primary" :href="link" target="_blank">Check On Explorer</a></div>
            </div>


            <p class="text-block">
                Current Max Pearl Supply is the actual total amount of originally minted 2,000,000,000 Pearl tokens available on the blockchain. While not all of them will be actively circulating on the market right now – once all Pearls are bought from Tortuga Marketplace and retrieved from the seabed, there will be no option to issue more. On the contrary, the <b>total Pearl supply will shrink over time, as tokens will be burned in the NFT creation process</b>, permanently removing them from circulation.
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, computed } from "vue";
    import { ConfigInstance } from "~/composables/Config";
    import { PearlReader } from "~/composables/PearlReader";
    import { BigNumber } from "bignumber.js";

    const saleAmount = ref("...");
    const maxTotalSupply = ref("...");
    const total = 1000000000;
    const link = ConfigInstance.blockchain.blockScanUrl + "/address/" + ConfigInstance.blockchain.contracts.pearl;

    onMounted(async () => {
        const reader = new PearlReader(ConfigInstance.blockchain);
        saleAmount.value = await reader.saleAmount();
        maxTotalSupply.value = await reader.tokenMaxTotalSupply();
    });

    const sold = computed(() => {
        if (saleAmount.value === "...") return "...";
        return new BigNumber(total).minus(new BigNumber(saleAmount.value)).toString();
    });

    const percent = computed(() => {
        if (saleAmount.value === "...") return "...";
        return new BigNumber(saleAmount.value).dividedBy(new BigNumber(total)).multipliedBy(100).toFixed(0);
    });

    const percentLeft =  computed(() => {
        if (percent.value === "...") return "...";
        return new BigNumber(100).minus(new BigNumber(percent.value));
    });

    function format(value: string | number) {
        if (!value || value === "...") {
            return value;
        }
        return new BigNumber(value).toFormat();
    }

</script>

<style scoped lang="scss">
    a.line-btn {
        border-radius: 86px;
        border: 1px solid var(--dark-4);
        font-size: 24px;
        text-transform: capitalize;
        padding: 12px 38px;
        margin: auto;
        color: var(--main-text-color);
        display: inline-flex;
        text-decoration: none;
    }

    .max-total-supply {
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 48px;
        display: flex;
    }

    @media screen and (max-width: 991px) {
        .pearl-wrapper {
            padding-top: 16px;
            padding-bottom: 24px;
        }
    }

    @media screen and (max-width: 767px) {
        .pearl-wrapper {
            padding-top: 8px;
            padding-bottom: 16px;
        }
    }

    @media screen and (max-width: 479px) {
        .pearl-wrapper {
            padding-top: 0;
        }
    }

    .max-token-supply-wrapper {
        margin-bottom: 48px;
    }

    @media screen and (max-width: 991px) {
        .max-token-supply-wrapper {
            margin-bottom: 16px;
        }
    }

    .card {
        border: 1px solid var(--blue-2);
        background: var(--blue-3);
        border-radius: 12px;
        transform: translate(0);
    }

    .card.main-cards {
        margin-bottom: 32px;
        padding: 33px 33px 53px;
    }

    @media screen and (max-width: 991px) {
        .card.main-cards {
            margin-bottom: 16px;
            padding: 20px;
            font-size: 16px;
            line-height: 1.63em;
        }
    }

    @media screen and (max-width: 767px) {
        .card.main-cards {
            padding: 16px;
            font-size: 14px;
        }
    }

    .sale-wrapper {
        margin-bottom: 32px;
        padding: 33px 33px 53px;
        grid-area: span 1 / span 1 / span 1 / span 1;
        transform: translate(0);
    }

    .pb-wrapper {
        position: relative;
        height: 20px;
        border-radius: 40px;
        overflow: hidden;
        width: 100%;
        margin: 12px 0px;
    }

    .total-supply {
        color: #fff3e4;
        margin-bottom: 0;
        padding-left: 8px;
        font-family: Eczar, sans-serif;
        font-size: 48px;
        font-weight: 700;
        line-height: 100%;
    }

    @media screen and (max-width: 991px) {
        .total-supply {
            font-size: 24px;
        }
    }

    .token-supply-wrapper {
        justify-content: space-between;
        display: flex;
        margin-bottom: 48px;
    }

    .token-info-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-right: 16px;
        line-height: 120%;
        display: flex;
    }

    .token-progress-wrapper {
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .progress-bar-bg {
        width: 100%;
        height: 40px;
        background-image: linear-gradient(#17353f, #000);
        border: 1px solid #3b7285;
        border-radius: 12px;
        position: relative;
        overflow: hidden;
    }

    .progress-bar-full {
        background-image: radial-gradient(circle at 100%, #60ff7b, #00bfff);
        border-right: 1px solid #8cfd9f;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
    }

    .progress-bar-text-wrapper {
        justify-content: center;
        align-items: center;
        font-weight: 800;
        display: flex;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
        font-family: Grenze, sans-serif;
        color: var(--main-text-color);
    }

    .token-info-wrapper.last {
        padding-left: 16px;
        padding-right: 0;
    }

    .text-white {
        color: var(--main-text-color);
        font-family: Grenze, sans-serif;
    }

    .text-numbers {
        font-family: Grenze, sans-serif;
    }

    @media screen and (max-width: 991px) {
        .token-info-wrapper {
            align-items: flex-start;
            padding-right: 8px;
            font-size: 14px;
        }

        .token-info-wrapper.last {
            align-items: flex-end;
            padding-left: 8px;
        }

        .token-supply-wrapper {
            flex-wrap: wrap;
            margin-top: 12px;
        }

        .token-progress-wrapper {
            width: 100%;
            flex: 1 0 auto;
            order: -1;
            margin-bottom: 8px;
        }
    }
</style>